<template>
  <v-row justify="start" class="height100" style="position: relative;">
    <v-col class="py-0 px-1">
      <SnackBar
        :snackMsg="snackMsg"
        :snackOn="snackOn"
        @close="snackOn = false"
      />
        <v-container
          class="px-0 pt-0 hs-slide-group"
          :style="`height: ${rootHeight + 95}px; position: relative;`"
        >
          <ScrollPagination
            v-if="!$route.query.id && !!myProfileId"
            :requestData="{
              'url': `personas`,
              'params': {
                query: this.personaQuery,
                profileId: this.myProfileId
              }
            }"
            :countData="{
              'url': `personas/count`,
            }"
            :rootHeight="`${rootHeight + 95}px`"
            :customPagination="paginationData"
            :forceUpdate="pendingOnBoarding"
            :setLoading="setPersonaLoading"
            :thin="false"
            class="pr-2"
          >
            <template v-slot:staticTitle>
              <v-container class="pt-0">
                <v-row align="center" class="px-6">
                  <v-icon>
                    {{ mdiAccountGroup }}
                  </v-icon>

                  <div class="mx-1"></div>

                  <div class="text-h6">
                    Communities
                  </div>

                  <div class="mx-1"></div>

                  <v-dialog
                    v-model="openCreateCommunity"
                    max-width="800"
                  >
                    <CreateOrUpdateCommunity />
                  </v-dialog>
                  <v-btn
                    elevation="0"
                    color="primary"
                    class="hs-rounded-12"
                    @click="openCreateCommunity = true"
                  >
                    {{ $t('community.createButtonLabel') }}
                    <v-icon right>
                      {{ mdiShimmer }}
                    </v-icon>
                  </v-btn>

                  <!-- <v-spacer></v-spacer>

                  <v-btn
                    elevation="0"
                    color="primary"
                    class="hs-rounded-12"
                  >
                    top creators
                  </v-btn> -->
                </v-row>
              </v-container>
            </template>
          
            <template v-slot:staticSlot>
              <SlideGroup  class="px-4">
                <v-slide-item
                  v-for="(item, index) in communities"
                  :key="index"
                >
                  <CommunityCard
                    :key="`hall_${index}`"
                    :id="item.id"
                    :thumbnail="item.thumbnail"
                    :name="item.name"
                    :about="item.about"
                    class="mb-4"
                  />
                </v-slide-item>
              </SlideGroup>
            </template>

            <template v-slot:scrollTitle>
              <v-container class="pb-2">
                <v-row align="center" class="px-6">
                  <v-icon>
                    {{ mdiDramaMasks }}
                  </v-icon>

                  <div class="mx-1"></div>

                  <div class="text-h6">
                    Personas
                  </div>

                  <div class="mx-1"></div>

                  <v-btn
                    elevation="0"
                    color="primary"
                    class="hs-rounded-12"
                    @click="$router.push('/personas/create')"
                  >
                    create
                    <v-icon right>
                      {{ mdiShimmer }}
                    </v-icon>
                  </v-btn>

                  <v-spacer></v-spacer>

                  <v-btn
                    elevation="0"
                    :color="btnColor"
                    class="hs-rounded-12"
                    @click="queryOnlyMyPersonas = !queryOnlyMyPersonas"
                  >
                    {{ queryOnlyMyPersonas ? 'all personas' : 'my personas' }}
                  </v-btn>
                </v-row>
              </v-container>
            </template>

            <template v-slot:child="props">
              <v-col
                :key="`persona_card_${props.index}`"
                class="py-0 px-0"
                cols="12" xs="6" sm="4"
              >
                <PersonaCard :persona="props.item"></PersonaCard>
              </v-col>
            </template>
          </ScrollPagination>

          <v-scroll-y-transition hide-on-leave>
            <Scrollbar
              :flexible="true"
              :thin="true"
              :style="`height: ${rootHeight}px;`"
              class="px-4"
            >
              <PersonaPage
                v-if="$route.query.id && personaId"
                :persona="persona"
                @snackMsg="snackMsg = $event; snackOn = true;"
              ></PersonaPage>
            </Scrollbar>
          </v-scroll-y-transition>
        </v-container>
      
    </v-col>
  </v-row>
</template>

<style>
.hs-slide-group .v-slide-group.v-item-group > .v-slide-group__next,
.hs-slide-group .v-slide-group.v-item-group > .v-slide-group__prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
}

.hs-slide-group .v-slide-group.v-item-group > .v-slide-group__next {
  right: 0%;
}

.hs-slide-group .v-slide-group.v-item-group > .v-slide-group__prev {
  left: 0%;
}

.hs-slide-group .v-slide-group__next .v-icon,
.hs-slide-group .v-slide-group__prev .v-icon {
  background-color: #4a148cd5; /* Purple color */
  border-radius: 50%;
  padding: 10px; /* Adjust padding as needed */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px; /* Adjust width as needed */
  height: 40px; /* Adjust height as needed */
}

.hs-slide-group .v-slide-group__next .v-icon__svg,
.hs-slide-group .v-slide-group__prev .v-icon__svg {
  fill: white; /* Adjust icon color if needed */
}
</style>

<style scoped>
.scroll-container {
  display: flex;
  overflow-x: auto; /* Enables horizontal scrolling */
  white-space: nowrap; /* Prevents columns from wrapping to the next line */
}
.scroll-container::-webkit-scrollbar {
  height: 8px; /* Adjust the height of the horizontal scrollbar */
}
.scroll-container::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar handle */
  border-radius: 4px; /* Rounded corners for the scrollbar handle */
}
.scroll-container::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar handle on hover */
}
</style>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { Scrollbar, SnackBar } from '@components/App'
import Header from '@components/Persona/Header'
import PersonaPage from '@components/Persona/PersonaPage'
import PersonaCard from '@components/Persona/Card'
import ScrollPagination from '@components/App/ScrollPagination.vue'
import API from '@api'
import CreateOrUpdateCommunity from '@components/Community/CreateOrUpdateCommunity'

import CommunityCard from '@components/Community/Card'
import SlideGroup from '@components/SlideGroup'
import {
  mdiAccountGroup,
  mdiDramaMasks,
  mdiShimmer
} from '@mdi/js'

export default {
  components: {
    SlideGroup,
    CommunityCard,
    ScrollPagination,
    SnackBar,
    Scrollbar,
    Header,
    PersonaPage,
    PersonaCard,
    CreateOrUpdateCommunity
  },

  async created() {
    
    this.communities = await API().get(`communities/${this.user.id}`)

    if (this.$route.query.id) {
      this.persona = await API().get(`persona/${this.user.id}`, {
        params: {
          id: this.$route.query.id
        }
      })

      if (this.persona.id)
        this.personaId = this.persona.id

      if (this.$route.query.activate && this.user) {
        this.SET_PERSONA(this.persona)
        this.SET_ANNA_CHAMBER()
      }
    }

    setTimeout(() => {
      // Prevents unwanted initial scrolls
      this.showHeader = true
    }, 500)
  },

  data: () => ({
    mdiAccountGroup,
    mdiDramaMasks,
    mdiShimmer,
    showHeader: false,
    communities: [],
    snackMsg: '',
    snackOn: false,
    loading: false,
    personaTag: 'All personas',
    persona: {},
    personaId: '',
    openCreateCommunity: false,
    queryOnlyMyPersonas: false
  }),

  watch: {
    annaChamber(value) {
      if(value) {
        // for smoothing the transition of the Anna Chamber
        this.loading = true 
        setTimeout(() => {
          this.loading = false
        }, 10)
      }
    },
    async $route (to) {
      if (to.query.id) {
        this.personaId = ''

        const persona = await API().get(`persona/${this.user.id}`, {
          params: {
            id: this.$route.query.id
          }
        })

        this.persona = persona        
        if (this.persona.id) this.personaId = this.persona.id
      }     
    }
  },

  computed: {
    ...mapGetters({
      scrollTop: 'scrollTop',
      innerHeight: 'innerHeight',
      user: 'user',
      myProfileId: 'profile/id',
      annaChamber: 'annaChamber',
      pendingOnBoarding: 'pendingOnBoarding',
      personaFilter: 'semantic-search/persona',
    }),

    btnColor() {
      if (this.queryOnlyMyPersonas) return 'primary'
      return this.$vuetify.theme.dark ? '' : 'grey lighten-2'
    },

    backgroundColor() {
      return this.$vuetify.theme.dark ? '#121212' : '#f8f4f4'
    },

    personaQuery() {
      const listQuery = {
        isPublic: {
          $eq: true
        },
        isUnlisted: {
          $eq: false
        },
        'sender.profile': {
          $ne: this.myProfileId
        }
      }

      if (this.personaFilter.value) {
        listQuery.name = {
          $regex: this.personaFilter.value,
          $options: 'i'
        }
      }

      const query = {
        $or: [
          {
            'sender.profile': this.myProfileId
          }
        ]
      }
      if (!this.queryOnlyMyPersonas)
        query['$or'].push(listQuery)

      return query
    },

    rootHeight() {
      return this.innerHeight * (1 - (100 / this.innerHeight)) - 80
    },

    slicedText() {
      return this.personaTag.length > 15 ? `${this.personaTag.slice(0, 6)}...` : this.personaTag;
    },

    borderStyle() {
      return this.$vuetify.theme.dark
        ? 'transparent !important;'
        : 'thin solid rgba(0, 0, 0, 0.12) !important;'
    },
    
    navColor() {
      return !this.$vuetify.theme.dark
        ? '#F7F7F7'
        : ''
    },

    paginationData() {
      if (document.getElementsByTagName('body')[0].clientHeight > 1240) {
        return { pageSize: 30, limit: 30 }
      }
      return { pageSize: 10, limit: 10 }
    }
  },

  methods: {
    ...mapActions({
      SET_PERSONA: 'SET_PERSONA',
    }),
    
    ...mapMutations({
      SET_ANNA_CHAMBER: 'setAnnaChamber',
      setPersonaLoading: 'semantic-search/setPersonaLoading',
    }),
  }
}
</script>