<template>
  <div class="custom-slide-group">
    <div class="custom-slide-group-content" ref="content">
      <slot></slot>
    </div>
    <v-icon
      v-if="showPrev"
      class="custom-slide-group__prev"
      @click="scrollPrev"
    >
      {{mdiChevronLeft}}
    </v-icon>
    <v-icon
      v-if="showNext"
      class="custom-slide-group__next"
      @click="scrollNext"
    >
      {{mdiChevronRight}}
    </v-icon>
  </div>
</template>

<script>
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js'

export default {
  name: "SlideGroup",
  data() {
    return {
      mdiChevronLeft,
      mdiChevronRight,
      showPrev: false,
      showNext: true,
    };
  },
  methods: {
    updateArrows(keepNext = false) {
      const content = this.$refs.content;
      if (!content) return;
    
      this.showPrev = content.scrollLeft > 0;
      this.showNext = keepNext || (content.scrollWidth > content.clientWidth + content.scrollLeft);
    },
    scrollNext() {
      const content = this.$refs.content;
      content.scrollBy({ left: 200, behavior: "smooth" });
    },
    scrollPrev() {
      const content = this.$refs.content;
      content.scrollBy({ left: -200, behavior: "smooth" });
    },
  },
  mounted() {
    this.updateArrows(true);
    this.$refs.content.addEventListener("scroll", () => {
      this.updateArrows()
    });
    window.addEventListener("resize", () => {
      this.updateArrows()
    });
  },
  beforeDestroy() {
    this.$refs.content.removeEventListener("scroll", this.updateArrows);
    window.removeEventListener("resize", this.updateArrows);
  },
};
</script>

<style>
.custom-slide-group {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.custom-slide-group-content {
  display: flex;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.custom-slide-group-content::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Webkit browsers */
}

.custom-slide-group__prev.v-icon--link,
.custom-slide-group__next.v-icon--link {
  position: absolute;
}

.custom-slide-group__prev,
.custom-slide-group__next {
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  background-color: #4a148cd5; /* Purple color */
  border-radius: 50%;
  padding: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.custom-slide-group__prev {
  left: 0;
}

.custom-slide-group__next {
  right: 0;
}

.custom-slide-group__prev .v-icon__svg,
.custom-slide-group__next .v-icon__svg {
  fill: white; /* Adjust icon color if needed */
}
</style>
